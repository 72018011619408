.stayDatePicker>div button+div .flex.items-stretch.flex-col .w-full:first-of-type .flex-none:first-of-type,
.stayDatePicker>div button+div .flex.items-stretch.flex-col .w-full:last-of-type .flex-none:last-of-type {
    display: none;
}

.stayDatePicker>div button+div .flex.items-stretch.flex-col .w-full:first-of-type .flex-none:last-of-type,
.stayDatePicker>div button+div .flex.items-stretch.flex-col .w-full:last-of-type .flex-none:first-of-type {
    position: absolute;
}

.stayDatePicker>div button+div .flex.items-stretch.flex-col .w-full:first-of-type .flex-none:last-of-type {
    right: 18px;
}

.stayDatePicker>div button+div .flex.items-stretch.flex-col .w-full:last-of-type .flex-none:first-of-type {
    left: 18px;
}

.stayDatePicker>div button+div .flex.items-stretch.flex-col .flex.items-center.justify-center.text-gray-400:disabled,
.stayDatePicker>div button+div .flex.items-stretch.flex-col button.text-gray-400 {
    opacity: 0;
}

.stayDatePicker>div button+div {
    --tw-translate-x: -27%;
}

.stayDatePicker>div button+div .rotate-45 {
    display: none;
}

@media screen and (max-width: 768px) {
    .stayDatePicker>div button+div {
        --tw-translate-x: 0;
    }
}

.modal-content .list-hotels {
    height: 600px;
    overflow-y: auto;
}

.modal .video-container {
    height: 470px;
    z-index: unset;
}

.video-container .cover-image {
    min-height: inherit;
    min-width: inherit;
    z-index: 1;
}

.video-container .react-player__preview {
    height: 100%;
    width: 100%;
}

.video-container .react-player__preview .react-player__shadow {
    background: none !important;
    height: unset !important;
    width: unset !important;
    border-radius: 50% !important;
}

.video-container .react-player__preview .react-player__shadow .react-player__play-icon {
    background-image: url(../img/icons/play.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    height: 80px;
    width: 80px;
    border: unset !important;
    margin: 0 !important;
}

.video-container .video-wrapper {
    width: 100% !important;
    height: 100% !important;
}

.\[\&\>li\]\:cursor-pointer>li button:disabled,
.\[\&\>li\]\:cursor-pointer>li button[disabled] {
    cursor: not-allowed;
    color: rgb(107 114 128);
}


.\[\&\>div\]\:mb-5 > div.validation-section{
    margin-bottom: 5rem;
}
  
.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}


.custom-select-details {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 20px);
    background-position-y: 25px;
  }

  .arrow-down {
    background-image: url("../img/icons/arrow-down-main-color.svg");
  }

  .arrow-top {
    background-image: url("../img/icons/arrow-top-main-color.svg");
  }